// Web
let http
const env = 'prod'
if (env == 'prod') {
        http = "https://"
} else {
        http = "https://" + process.env.VUE_APP_NAME_PREFIX + "-"
}

// export const http                           = "https://" // สำหรับ dev
export const head                           = http
export const homepage                       = head + "www.packhai.com/"
export const customerWebsite                = head + "customer.packhai.com/"
export const shopCallbackService            = head + "shop.packhai.com/platform/"
export const hostService                    = head + "service.packhai.com/"
export const packhaiApi88                   = ".packhai-api-88.com/"

// service python
export const globalService                  = hostService + "global-service/"
export const addOrderService                = hostService + "add-order-service/"
export const registerService                = hostService + "register-service/"
export const chinaPackingService            = hostService + "china-packing-service/"
export const shopOverviewService            = hostService + "shop-overview-service/"
export const stockService                   = hostService + "stock-service/"
export const reportService                  = hostService + "report-service/"
export const manageCategoryService          = hostService + "manage-category-service/"
export const manageShopService              = hostService + "manage-shop-service/"
export const financialService               = hostService + "financial-service/"
export const mhaoService                    = hostService + "mhao-service/"

// Service .Net
export const stockService_dotnet            = head + "stock" + packhaiApi88
export const agentService_dotnet            = head + "ssplatform" + packhaiApi88
export const ecomService_dotnet             = head + "ecom" + packhaiApi88
export const generalService_dotnet          = head + "general" + packhaiApi88
export const branchService_dotnet           = head + "branch" + packhaiApi88
export const shop_Service                   = head + "shop" + packhaiApi88 
export const report_Service                 = head + "report" + packhaiApi88
export const logistic_Service               = head + "logistic" + packhaiApi88
export const reportService_dotnet           = head + "report" + packhaiApi88
export const orderService_dotnet            = head + "order" + packhaiApi88 
export const shopService_dotnet             = head + "shop" + packhaiApi88  
export const partnerService_dotnet     	    = head + "partner" + packhaiApi88

 //export const stockService_dotnet         = "https://localhost:5001/"
